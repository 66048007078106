import $ from "jquery"
try {
  require("jquery.easing") // dat works :3
} catch (e) {}

import icons from "../icons/icons"
import "select2"
import "select2/dist/css/select2.css"
import Swiper, {
  EffectFade,
  Navigation,
  Pagination,
  Mousewheel,
  EffectCoverflow,
} from "swiper"
import "swiper/swiper-bundle.css"
import tippy, { delegate } from "tippy.js"
import "tippy.js/dist/tippy.css"
import "tippy.js/animations/scale.css"
import { Fancybox } from "@fancyapps/ui"
import "@fancyapps/ui/dist/fancybox.css"

Swiper.use([Navigation, Pagination, EffectFade, Mousewheel, EffectCoverflow])

tippy("[data-tippy-content]", {
  animation: "scale",
})
// delegate(".js-universities-select-items", {
//   target: ".js-universities-select-item",
//   animation: "scale",
// })
delegate(".js-compare-items", {
  target: ".js-compare-item",
  animation: "scale",
})

const template = document.getElementById("reset-filter-tippy")
if (template) {
  template.style.display = "block"

  tippy(".universities__side__bottom__reset", {
    content: template,
    trigger: "click",
    interactive: true,
    theme: "alert",
    animation: "scale",

    onShow(instance) {
      instance.popper
        .querySelector(".js-tippy-close")
        .addEventListener("click", () => {
          instance.hide()
        })
      instance.popper
        .querySelector(".js-universities-reset")
        .addEventListener("click", () => {
          instance.hide()
        })
    },
  })
}

var app = {
  iconSpriteFn() {
    icons.forEach(iconSpriteFn)
    function iconSpriteFn(item, index) {
      const iconSprite = document.querySelector("#iconSprite")
      if (iconSprite) {
        iconSprite.innerHTML +=
          "<div class='icon-sprite__item'>" +
          "<span class='icon-sprite__number'>" +
          (index + 1) +
          "</span>" +
          "<div class='icon-sprite__preview'>" +
          item.iconSvg +
          "</div>" +
          "<div class='icon-sprite__name'>" +
          item.iconId +
          "</div>" +
          "</div>"
      }

      const icon = document.querySelectorAll(".icon")
      if (icon) {
        Array.prototype.forEach.call(icon, (el) => {
          let dataIconId = el.getAttribute("data-icon-id")
          if (dataIconId == item.iconId) {
            el.innerHTML = item.iconSvg
          }
        })
      }
    }
  },
  accordionFn() {
    $("body").on("click", ".js-accordion-trigger", function () {
      $(this).toggleClass("active")
      const accordionGroup = $(this).closest(".accordion-wrapper")
      const item = $(this).closest(".accordion-item")
      let multipleShow = false

      if (accordionGroup.data("multiple-show") == true) {
        multipleShow = true
      } else {
        $(".js-accordion-trigger").not(this).removeClass("active")
      }
      let content = item.find(".accordion-calc")
      let contentHeight = content.outerHeight(true)
      if (item.hasClass("active") && !$(this).hasClass("force-open")) {
        item.find(".accordion-content").css("height", 0 + "px")
        item.removeClass("active")

        if (!multipleShow) {
          accordionGroup.children(".accordion-item").removeClass("active")
          accordionGroup
            .children(".accordion-item")
            .find(".accordion-content")
            .css("height", 0 + "px")
        }
        item.addClass("active")
        item.find(".accordion-content").css("height", contentHeight)
        // $(".js-cv-upload").each(function () {
        //   $(this).one("click", function () {
        //     $(this).parent().next("input").trigger("click");
        //   });
        // });

        // $(".cv-upload").on("change", function () {
        //   if ($(".cv-upload").val() !== "") {
        //     $(".js-cv-upload")
        //       .find(".input-item__placeholder")
        //       .text("Dosya Seçildi");
        //   }
        // });
      }
    })

    $(".accordion-item.opened .js-accordion-trigger").trigger("click")
  },
  load() {
    console.log("load")
  },
  resized() {
    console.log("resized")
  },

  universitiesFilterFn() {
    var appendItems = ".js-universities-select-items"

    function outerControl() {
      if ($(".js-universities-select-item").length === 0) {
        $(".js-universities-select").css("display", "none")
      } else {
        $(".js-universities-select").css("display", "block")
      }
    }
    outerControl()

    $(".universities__side__items .custom-checkbox input").each(function (
      valThis
    ) {
      $(this).on("change", function () {
        var items = []
        var val = $(this).val()
        valThis = val
        var appendBadge = `<div class="universities__side__select__item js-universities-select-item" data-value="${val}">
        <div class="txt txt--px14 txt--font400">${val}</div>
    </div>`
        if ($(this).is(":checked")) {
          $(appendItems).append(appendBadge)
          $(this)
            .closest(".universities__side__item")
            .find(".js-universities-hidden")
            .append(appendBadge)
          var textVal = $(this)
            .closest(".universities__side__item")
            .find(".js-universities-hidden .txt.txt--px14")
          textVal.each(function () {
            items.push($(this).text())
          })
          $(this)
            .closest(".universities__side__item")
            .find(".box")
            .val(items.join("|"))
        } else {
          $(".js-universities-select-items .js-universities-select-item").each(
            function () {
              if (val === $(this).data("value")) {
                $(this).remove()
              }
            }
          )
          $(this)
            .closest(".universities__side__item")
            .find(".js-universities-hidden .js-universities-select-item")
            .each(function () {
              if (val === $(this).data("value")) {
                $(this).remove()
              }
            })
          var textVal = $(this)
            .closest(".universities__side__item")
            .find(".js-universities-hidden .txt.txt--px14")
          textVal.each(function () {
            items.push($(this).text())
          })
          $(this)
            .closest(".universities__side__item")
            .find(".box")
            .val(items.join("|"))
        }
        outerControl()
        // $("body").delegate(
        //   ".js-universities-select-items .js-universities-select-item, js-universities-hidden .js-universities-select-item",
        //   "click",
        //   function () {
        //     var thisVal = $(this).data("value")
        //     $(
        //       `.universities__side__items .custom-checkbox input[value='${thisVal}']`
        //     ).prop("checked", "")
        //     items = []
        //     $(this)
        //       .closest(".universities__side__filter")
        //       .find(".js-universities-select .js-universities-select-item")
        //       .each(function (e, v) {
        //         if ($(v).data("value") !== thisVal) {
        //           items.push($(v).data("value"))
        //         }
        //       })
        //     $(this)
        //       .closest(".universities__side__filter")
        //       .find(".box")
        //       .val(items.join("|"))
        //     $(this).remove()
        //     outerControl()
        //   }
        // )
      })
    })
    $("body").delegate(".js-universities-reset", "click", function () {
      console.log($(this))
      $(this).closest("form")[0].reset()
      $(".box").each(function () {
        $(this).val("")
      })
      $(".js-universities-select-item").remove()
      outerControl()
    })
  },

  detailPageControl() {
    if ($(".detail-page").length > 0) {
      $(".header").addClass("header--light")
    }
  },
  counterNumber() {
    var a = 0
    $(window).scroll(function () {
      if ($(".about__info__count").length > 0) {
        var oTop = $(".about__info__item").offset().top - window.innerHeight
        console.log(oTop)
        if (a == 0 && $(window).scrollTop() > oTop) {
          $(".about__info__count h3").each(function () {
            var $this = $(this),
              countTo = $this.attr("data-count")
            $({
              countNum: $this.text(),
            }).animate(
              {
                countNum: countTo,
              },

              {
                duration: 2000,
                easing: "swing",
                step: function () {
                  $this.text(Math.floor(this.countNum))
                },
                complete: function () {
                  $this.text(this.countNum)
                },
              }
            )
          })
          a = 1
        }
      }
    })
  },
  searchFn() {
    $(".js-open-search").on("click", function () {
      $(this).toggleClass("active")
      $(".js-search").toggleClass("active")
      $(".header__right").toggleClass("disabled")
      $(".header__tools-login").toggleClass("disabled")
    })
  },
  headerMenuFn() {
    $(".js-open-menu").on("click", () => {
      $(".js-open-menu").toggleClass("opened")
      $(".js-mobile-menu").toggleClass("opened")
      $(".header").toggleClass("header--fixed")
      $(".header").toggleClass("header--with-menu")
      $("body, html").toggleClass("o-hidden")
    })
    $(".js-mobile-menu-dropdown").click(function () {
      $(".mobile-menu__dropdown").removeClass("opened")
      $(this).find(".mobile-menu__dropdown").toggleClass("opened")
    })
  },

  showHidePassword() {
    $("body").on("click", ".js-show-hide-password", function () {
      if ($(this).hasClass("active")) {
        $(this).removeClass("active")
        $(this).parent().find("input").prop("type", "password")
      } else {
        $(this).addClass("active")
        $(this).parent().find("input").prop("type", "text")
      }
    })
  },
  mainSlider() {
    const swiper = new Swiper(".js-main-slider", {
      loop: true,
      slidesPerView: 2.3,
      centeredSlides: true,
      spaceBetween: 40,
      breakpoints: {
        320: {
          slidesPerView: 1.2,
          spaceBetween: 20,
        },
        577: {
          slidesPerView: 1.4,
        },
        993: {
          slidesPerView: 2.3,
        },
      },
    })
  },
  universitiesSlider() {
    const swiper = new Swiper(".js-university-slider", {
      loop: true,
      slidesPerView: 2,
      spaceBetween: 40,
      breakpoints: {
        320: {
          slidesPerView: 1.1,
          spaceBetween: 20,
        },
        577: {
          slidesPerView: 1.2,
        },
        993: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
      },
    })
    const swiperTab = new Swiper(".js-universities-detail-tab-slider", {
      loop: false,
      slidesPerView: "auto",
      spaceBetween: 5,
      navigation: {
        nextEl: ".universities-detail__content--pagination .swiper-button-next",
        prevEl: ".universities-detail__content--pagination .swiper-button-prev",
      },
    })
  },
  howtoapplyStudenSlider() {
    const swiper = new Swiper(".js-howtoapply-student-slider", {
      loop: true,
      slidesPerView: 1.5,
      centeredSlides: true,
      spaceBetween: 40,
    })
  },
  heroSlider() {
    const swiper = new Swiper(".js-hero-slider", {
      loop: true,
      effect: "fade",
      pagination: {
        el: ".js-hero-pagination",
        Type: "custom",
        clickable: true,
      },
    })
  },
  select2Options() {
    // $(".js-select-sort")
    //   .select2({
    //     dropdownParent: $(".search-select--sort"),
    //     placeholder: "Sort By",
    //   })
    //   .on("change", function (each) {
    //     console.log(this.value)
    //   })
    $(".js-select-degree").select2({
      dropdownParent: $(".search-select--degree"),
      placeholder: "Degree",
    })
    $(".js-select-field").select2({
      dropdownParent: $(".search-select--field"),
      placeholder: "Field",
    })
    $(".js-select-program").select2({
      dropdownParent: $(".search-select--program"),
      placeholder: "Program",
    })
    $(".js-select-country").select2({
      dropdownParent: $(".search-select--country"),
      placeholder: "Country",
    })

    $(".js-select-language").select2({
      dropdownParent: $(".search-select--language"),
      placeholder: "Language",
    })
    $(document).on("select2:open", () => {
      document.querySelector(".select2-search__field").focus()
    })
  },
  mainTab: function () {
    $("body").on("click", ".js-tab-item", function (e) {
      $(".js-tab-item").removeClass("active")
      $(".js-tab-item.active").removeClass("active")
      $('.js-tab-item[data-filter="' + $(this).data("filter") + '"]').addClass(
        "active"
      )
      var dataFilter = $(this).data("filter")
      $(".filter-tab-item").delay(250).addClass("filtering")
      // $('.js-filter-tab-item.' + dataFilter).removeClass('filtering');
      window.setTimeout(function () {
        $(".filter-tab-item").removeClass("filtering")
        $(".filter-tab-item").addClass("filtered")
        $(".filter-tab-item." + dataFilter).removeClass("filtered")
      }, 250)
      e.preventDefault()
      return false
    })
  },
  gridListViewFn() {
    $(".js-grid-view").on("click", function () {
      $(".js-list-view").removeClass("active")
      $(this).addClass("active")
      $(".universities__inner").removeClass("universities__inner--list")
    })
    $(".js-list-view").on("click", function () {
      $(".js-grid-view").removeClass("active")
      $(this).addClass("active")
      $(".universities__inner").addClass("universities__inner--list")
    })
    setTimeout(() => {
      $(".js-list-view").trigger("click")
    }, 410)
    $(".js-open-filter-side").on("click", function () {
      $(".universities__side").addClass("active")
      $(".universities__side__overlay").addClass("active")
    })
    $(".js-close-filter-side").on("click", function () {
      $(".universities__side__overlay").removeClass("active")
      $(".universities__side").removeClass("active")
    })
  },

  closeTab() {
    $(".favorites-card__delete").click(function () {
      console.log(21)
      $(this).parent().fadeOut(300)
      setTimeout(() => {
        $(this).parent().remove()
      }, 350)
    })
    $(".js-btn-fav").click(function () {
      $(this).toggleClass("add-fav")
    })
  },

  stepsApplySlider() {
    const stepsApplySwiper = new Swiper(".js-stepsapply-slider", {
      loop: false,
      direction: "vertical",
      slidesPerView: 5,
      spaceBetween: 30,
      slideToClickedSlide: false,
      centeredSlides: false,
      centeredSlidesBounds: false,
      watchSlidesVisibility: true,
      breakpoints: {
        320: {
          direction: "horizontal",
          slidesPerView: 1.2,
          spaceBetween: 5,
        },
        577: {
          slidesPerView: 1.1,
        },

        993: {
          direction: "vertical",
          slidesPerView: 5,
          spaceBetween: 30,
        },
      },
    })
    // stepsApplySwiper.on("slideChangeTransitionStart", function () {
    //   $(".js-stepsapply-slider .swiper-slide-active .js-tab-item").click();
    // });
  },

  newsSlider() {
    var newsSwiper = new Swiper(".js-news-slider", {
      effect: "coverflow",
      grabCursor: true,
      loop: true,
      centeredSlides: false,
      keyboard: true,
      spaceBetween: 40,
      slidesPerView: 3.5,
      speed: 500,
      simulateTouch: true,
      coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 0,
        modifier: 1,
        slideShadows: false,
      },

      breakpoints: {
        320: {
          slidesPerView: 1.1,
        },
        577: {
          slidesPerView: 1.2,
        },
        769: {
          slidesPerView: 1.5,
        },
        993: {
          slidesPerView: 2.5,
        },
        1201: {
          slidesPerView: 2.5,
        },
        1441: {
          slidesPerView: 3.5,
        },
      },
    })
  },
  inputFocusandTexted() {
    $(".input-item input, .input-item textarea").each(function () {
      if ($(this).val() === "") {
        $(this).parent(".input-item").removeClass("input-item--valued")
      } else {
        $(this).parent(".input-item").addClass("input-item--valued")
      }
    })
    $(".input-item input, .input-item textarea").focus(function () {
      $(this).parent(".input-item").addClass("input-item--focused")
    })
    $(".input-item input, .input-item textarea").focusout(function () {
      if ($(this).val() === "") {
        $(this).parent(".input-item").removeClass("input-item--valued")
        $(this).parent(".input-item").removeClass("input-item--focused")
      } else {
        $(this).parent(".input-item").addClass("input-item--valued")
        $(this).parent(".input-item").removeClass("input-item--focused")
      }
    })
  },
  modalFn: function () {
    $(".js-open-modal").on("click", function (e) {
      e.preventDefault()
      $(".overlay").addClass("active")
      var id = $(this).data("modal-id")
      $(".js-modal").removeClass("active")
      $('.js-modal[data-modal-id="modal-' + id + '"]').addClass("active")
    })
    $(".js-close-modal").on("click", function () {
      $(".overlay").removeClass("active")
      $(".js-modal").removeClass("active")
    })
    $("body").on("click", ".overlay", function () {
      $(".overlay").removeClass("active")
      $(".js-modal").removeClass("active")
    })
  },
  countryScroll() {
    if ($(".js-scroll-trigger").length > 0) {
      $(document).on("scroll", onScroll)
      function onScroll() {
        var scrollPos = $(document).scrollTop()
        $(".js-scroll-trigger").each(function () {
          var refElement = $($(this).attr("href"))
          if (refElement.position().top <= scrollPos + 50) {
            $(".js-scroll-trigger").removeClass("active")
            $(this).addClass("active")
          } else {
            $(this).removeClass("active")
          }
        })
      }
      $(".js-scroll-trigger").click(function () {
        if (
          location.pathname.replace(/^\//, "") ==
            this.pathname.replace(/^\//, "") &&
          location.hostname == this.hostname
        ) {
          var target = $(this.hash)
          target = target.length
            ? target
            : $("[name=" + this.hash.slice(1) + "]")
          if (target.length) {
            $("html, body").animate(
              {
                scrollTop: target.offset().top,
              },
              1000,
              "easeInOutExpo"
            )
            onScroll()
            return false
          }
        }
      })
    }
  },

  universitiesAccordion() {
    $(".js-universities-accordion").each(function () {
      $(this)
        .find(".universities__side__item__top")
        .on("click", function () {
          if ($(this).parent().hasClass("opened")) {
            $(this).parent().removeClass("opened")
          } else {
            $(".js-universities-accordion").removeClass("opened")
            $(this).parent().addClass("opened")
          }
        })
    })
  },

  comparePopup() {
    if ($(".js-compare-btn").length > 0) {
      $(".js-compare-btn").each(function () {
        $(this).on("click", function () {
          if (
            $(".universities-card.active").length < 2 ||
            $(this).hasClass("active")
          ) {
            var compareTxt = $(this)
              .closest(".universities-card")
              .find(".js-compare-txt")
              .text()
            var compareItem = `<div class="compare-popup__item js-compare-item" data-tippy-content="Remove"> <span class="txt txt--px18 txt--font400">${compareTxt}</span><span class="icon icon-font" data-icon-id="iconClose">    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 512.001 512.001">
       <path d="M284.286,256.002L506.143,34.144c7.811-7.811,7.811-20.475,0-28.285c-7.811-7.81-20.475-7.811-28.285,0L256,227.717
         L34.143,5.859c-7.811-7.811-20.475-7.811-28.285,0c-7.81,7.811-7.811,20.475,0,28.285l221.857,221.857L5.858,477.859
         c-7.811,7.811-7.811,20.475,0,28.285c3.905,3.905,9.024,5.857,14.143,5.857c5.119,0,10.237-1.952,14.143-5.857L256,284.287
         l221.857,221.857c3.905,3.905,9.024,5.857,14.143,5.857s10.237-1.952,14.143-5.857c7.811-7.811,7.811-20.475,0-28.285
         L284.286,256.002z"/>
     </svg></span></div>`
            $(".js-compare-popup").addClass("active")
            if ($(this).hasClass("active")) {
              $(this).removeClass("active")
              $(this).closest(".universities-card").removeClass("active")
              $(".js-compare-item").each(function () {
                if (compareTxt == $(this).find(".txt").text()) {
                  $(this).remove()
                }
              })
            } else {
              $(this).addClass("active")
              $(this).closest(".universities-card").addClass("active")
              $(".js-compare-items").append(compareItem)
            }
          }
          if ($(".universities-card.active").length === 0) {
            $(".js-compare-popup").removeClass("active")
          }
        })
      })

      $("body").delegate(
        ".js-compare-items .js-compare-item",
        "click",
        function () {
          var thisText = $(this).find(".txt").text()
          $(".universities-card").each(function () {
            if (thisText === $(this).find(".js-compare-txt").text()) {
              $(this).removeClass("active")
              $(this).find(".js-compare-btn").removeClass("active")
            }
          })
          $(this).remove()
          if ($(".js-compare-item").length === 0) {
            $(".js-compare-popup").removeClass("active")
          }
        }
      )
    }
    var $list = $(".js-universities-sort-list")

    var tabSort = ["university", "program", "degree", "language"]
    for (let i = 0; i < tabSort.length; i++) {
      const e = tabSort[i]
      $(`.js-sort-${e}-tab`).on("click", function () {
        $(this).find(".icon").css("display", "block")
        if ($(this).hasClass("active")) {
          $(this).removeClass("active")
          $list
            .children(".universities-card")
            .detach()
            .sort(function (a, b) {
              return $(a)
                .find(`.js-${e}-sort-item`)
                .text()
                .localeCompare($(b).find(`.js-${e}-sort-item`).text())
            })
            .appendTo($list)
        } else {
          $(this).addClass("active")
          $list
            .children(".universities-card")
            .detach()
            .sort(function (a, b) {
              return $(b)
                .find(`.js-${e}-sort-item`)
                .text()
                .localeCompare($(a).find(`.js-${e}-sort-item`).text())
            })
            .appendTo($list)
        }
      })
    }
  },

  regionSlider: function () {
    const regionSwiper = new Swiper(".js-region-slider", {
      effect: "coverflow",
      loop: true,
      slidesPerView: 3.7,
      spaceBetween: 40,
      navigation: {
        nextEl: ".js-region-slider .swiper-button-next",
        prevEl: ".js-region-slider .swiper-button-prev",
      },
      coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 0,
        modifier: 1,
        slideShadows: false,
      },
      breakpoints: {
        320: {
          slidesPerView: 1.1,
          spaceBetween: 20,
        },
        577: {
          slidesPerView: 1.2,
        },
        769: {
          slidesPerView: 1.5,
        },
        993: {
          slidesPerView: 2.5,
          spaceBetween: 20,
        },
        1201: {
          slidesPerView: 2.8,
        },
        1441: {
          slidesPerView: 3.7,
          spaceBetween: 40,
        },
      },
    })
    $(".js-height-card").each(function () {
      setTimeout(() => {
        if ($(".universities-card").length > 0) {
          var regionCardHeight =
            $(this).find(".js-height-card-inner").outerHeight(true) + 20
          var regionTitleHeight =
            $(this).find(".js-height-card-txt").outerHeight(true) + 20
        } else {
          var regionCardHeight =
            $(this).find(".js-height-card-inner").outerHeight(true) + 40
          var regionTitleHeight =
            $(this).find(".js-height-card-txt").outerHeight(true) + 40
        }

        $(this)
          .find(".js-height-card-content")
          .css("height", regionTitleHeight + "px")
        $(this).hover(
          function () {
            $(this)
              .find(".js-height-card-content")
              .css("height", regionCardHeight + "px")
          },
          function () {
            $(this)
              .find(".js-height-card-content")
              .css("height", regionTitleHeight + "px")
          }
        )
      }, 400)
    })
  },

  filterListSearch: function () {
    function filterListSearchA(
      search_field,
      searchable_elements,
      searchable_text_class
    ) {
      $(search_field).keyup(function (e) {
        e.preventDefault()
        // console.log(e.target.closest(".universities__side__item").children[3])
        var asd = e.target.closest(".universities__side__item").children[3]
          .children
        var query = $(this).val().toLowerCase()
        if (query) {
          $.each($(asd), function () {
            var title = $(this).text().toLowerCase()
            if (title.indexOf(query) == -1) {
              $(this).hide()
            } else {
              $(this).show()
            }
          })
        } else {
          $(searchable_elements).show()
        }
      })
    }
    filterListSearchA(
      ".js-filter-side-list-search",
      ".js-filter-side-list-item",
      ".js-filter-side-list-item-txt"
    )
  },

  init: function () {
    app.iconSpriteFn()
    app.load()
    app.detailPageControl()
    app.searchFn()
    app.heroSlider()
    app.select2Options()
    app.regionSlider()
    app.mainTab()
    app.stepsApplySlider()
    app.newsSlider()
    app.mainSlider()
    app.accordionFn()
    app.inputFocusandTexted()
    app.universitiesAccordion()
    app.countryScroll()
    app.universitiesFilterFn()
    app.gridListViewFn()
    app.universitiesSlider()
    app.howtoapplyStudenSlider()
    app.modalFn()
    app.showHidePassword()
    app.counterNumber()
    app.headerMenuFn()
    app.closeTab()
    app.comparePopup()
    app.filterListSearch()
  },
}

function docReadied(fn) {
  window.addEventListener("DOMContentLoaded", fn)
}
function docResized(fn) {
  window.addEventListener("resize", fn)
}
docReadied(() => {
  app.init()
})

docResized(() => {
  app.resized()
})

export default { docResized, docReadied }
